import React from "react"
import logo from "../images/logo.png"
import W3Bar from "../components/W3Bar/W3Bar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import styled from "styled-components"
import { Link } from "gatsby"

const LogoLink = styled(Link)`
  margin-left: 10px;
  width: 220px;
  margin-right: auto;
`

const EndDiv = styled.div`
  margin-left: auto;
  width: 220px;
  font-size: 12px;
  align-items: center;
  cursor: pointer;
  margin-top: auto;
`
const W3BarChirurghiContainer = ({ pathname }) => {
  return (
    <W3Bar>
      <LogoLink to={"/"}>
        <img height="50px" src={logo} />
      </LogoLink>
      <div
        style={{
          marginTop: 10,
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <W3Bar.Item active={pathname == "/cosa_offriamo"} to={"/cosa_offriamo"}>
          Cosa offriamo
        </W3Bar.Item>
        <W3Bar.Item
          active={pathname == "/chirurgo_login"}
          to={"http://backend.chirurghiestetici.it/admin_chirurgo/"}
        >
          Accesso Dottore
        </W3Bar.Item>
        <W3Bar.Item
          active={pathname == "/chirurgo_register"}
          to={"/chirurgo_register"}
        >
          Registrati gratis
        </W3Bar.Item>
      </div>
      <EndDiv>
        <W3Bar.ItemDiv to={"/"}>
          <FontAwesomeIcon
            style={{ fontSize: 11, marginLeft: 5, fontWeight: 100 }}
            icon={faArrowLeft}
          />
          <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>
            Torna a chirurghi estetici
          </span>
        </W3Bar.ItemDiv>
      </EndDiv>
    </W3Bar>
  )
}

export default W3BarChirurghiContainer
