import React from "react"
import { StyledMenu, MenuItem } from "./styles/Menu.styled"

const ChirurgoMenuLeft = ({ open }) => {
  return (
    <StyledMenu open={open}>
      <MenuItem to="/cosa_offriamo">Cosa offriamo</MenuItem>
      <MenuItem to="http://backend.chirurghiestetici.it/admin_chirurgo/">Accedi</MenuItem>
      <MenuItem to="/chirurgo_register" style={{ border: "none" }}>
        Registrati
      </MenuItem>
    </StyledMenu>
  )
}
export default ChirurgoMenuLeft
