import React from "react"
import ChirurgoRegisterContainer from "../components/ChirurgoRegisterContainer/ChirurgoRegisterContainer"
import LayoutChirurghi from "../components/layoutChirurghi"
import SEO from "../components/seo"

const ChirurgoRegister = ({ location }) => {
  return (
    <LayoutChirurghi pathname={location.pathname}>
      <SEO title="Registrati gratis" />
      <ChirurgoRegisterContainer />
    </LayoutChirurghi>
  )
}

export default ChirurgoRegister
