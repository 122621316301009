/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import "./layout.css"
import FooterContainer from "../containers/footer"

import W3barContainer from "../containers/w3bar"
import MobileBar from "./MobileBar/MobileBar"
import { ThemeProvider } from "styled-components"
import { GlobalStyles } from "../constants/global-styles"
import W3BarChirurghiContainer from "../containers/w3barChirurghi"
import MobileBarChirurghi from "./MobileBarChirurgi/MobileBarChirurghi"
import { Snackbar } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { useDispatch, useSelector } from "react-redux"
import {
  closeToast,
  selectSeverity,
  selectText,
  selectToast,
} from "../redux/toastSlice"

const theme = {
  blackblue: "#001229",
  darkblue: "#172d44",
  midblue: "#264a79",
  darkocean: "#85AFD4",
  ocean: "rgba(51, 122, 183, 0.2)",
  lightocean: "rgba(51, 122, 183, 0.1)",
  blue: "#337AB7",
  lightblue: "#5492E3",
  whitegray: "#f8f9fa",
  gray: "#797979",
}

const LayoutChirurghi = ({ children, pathname }) => {
  const toast = useSelector(selectToast)
  const text = useSelector(selectText)
  const severity = useSelector(selectSeverity)
  const dispatch = useDispatch()
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    dispatch(closeToast())
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <MobileBarChirurghi pathname={pathname} />
      <W3BarChirurghiContainer pathname={pathname} />
      <div className={"spacer"} />
      {children}
      <FooterContainer />
      <Snackbar open={toast} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {text}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  )
}

LayoutChirurghi.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutChirurghi
