import React, { useEffect } from "react"
import { Col, Container as BTContainer, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { colors } from "../../constants/Colors"
import {
  selectChirurgoRegisterLoading,
  selectChirurgoRegisterPage,
  startFetchingChirurgoRegisterAsync,
} from "../../redux/chirurgoregisterSlice"
import CustomButton from "../Atoms/Button"
import SeAll from "../Atoms/SeAll"
import CheckBox from "../CheckBox/CheckBox"
import ContactForm from "../Form/Form"
import LoadingView from "../LoadingView/LoadingView"
import { navigate } from "gatsby"
const Title = styled.p`
  font-weight: 400;
  color: ${({ theme }) => theme.midblue};
  font-size: 22px;
  text-align: center;
  margin-bottom: 0;

  @media (max-width: 1024px) {
    font-size: 18px;
    line-height: 1.3;
  }
`
const Container = styled(BTContainer)`
  background: white;
  border-top: 20px solid black;
  border-top-color: ${({ theme }) => theme.ocean};
  padding-bottom: 100px;
`

const SubTitle = styled.p``

const Body = styled.p``

const LeftContainer = styled(Col)`
  object-fit: cover;
  background-image: url(${props => props.img});
  background-size: cover;
  background-position: right;
  padding: 5%;

  & > ${Title} {
    color: ${({ theme }) => theme.blackblue};
    &:nth-child(1) {
      font-size: 30px;
    }

    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
  }
  & > ${SubTitle} {
    color: ${({ theme }) => theme.lightblue};
    font-size: 22px;
    font-weight: 400;
    text-align: left;
    margin-top: 60px;
  }

  @media (max-width: 1024px) {
    & > ${Title} {
      &:nth-child(1) {
        font-size: 22px;
      }
    }
  }
`
const RightContainer = styled(Col)``

const SeeAllContainer = styled.div`
  font-size: 24px;
`

export default function ChirurgoRegisterContainer() {
  const dispatch = useDispatch()
  const [accept, setAccept] = React.useState(false)
  const page = useSelector(selectChirurgoRegisterPage)
  const loading = useSelector(selectChirurgoRegisterLoading)

  useEffect(() => {
    dispatch(startFetchingChirurgoRegisterAsync())
  }, [])

  if (loading) {
    return <LoadingView />
  }
  return (
    <Container fluid>
      <Row>
        <LeftContainer img={require("../../images/register_bg.png")} md={7}>
          <Title>
            {page["block_title"].nome} <br /> {page["block_title"].descrizione}
          </Title>
          <SubTitle>{page["text_blue"].nome}</SubTitle>
          <Title>{page["block_item_1"].nome}</Title>
          <Body>{page["block_item_1"].descrizione}</Body>
          <Title>{page["block_item_2"].nome}</Title>
          <Body>{page["block_item_2"].descrizione}</Body>
          <Title>{page["block_item_3"].nome}</Title>
          <Body>{page["block_item_3"].descrizione}</Body>

          <SeAll
            onClick={() => navigate("/cosa_offriamo")}
            style={{ color: colors.LIGHT_BLUE, fontSize: 24, marginTop: 40 }}
            fontWeight={350}
            text={"Scopri di più"}
          />
        </LeftContainer>
        <RightContainer md={5}>
          <ContactForm
            style={{
              paddingTop: 20,
              paddingBottom: 20,
              maxWidth: 400,
              margin: "auto",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <Title>Registrati</Title>
            <ContactForm.Wrapper style={{ padding: 20, paddingTop: 5 }}>
              <ContactForm.Input
                title={"noresize"}
                placeholder={"Nome Professionale"}
              />
              <ContactForm.Input title={"noresize"} placeholder={"Indirizzo"} />
              <ContactForm.Input title={"noresize"} placeholder={"Città"} />
              <ContactForm.Input title={"noresize"} placeholder={"Cap"} />
              <ContactForm.Input title={"noresize"} placeholder={"Telefono"} />
              <ContactForm.Input title={"noresize"} placeholder={"Email"} />
              <CheckBox onClick={() => setAccept(!accept)}>
                Accetto i termini e condizioni
                <CheckBox.Input
                  type="checkbox"
                  checked={accept ? "checked" : false}
                />
                <CheckBox.CheckMark></CheckBox.CheckMark>
              </CheckBox>
              <CustomButton
                color={colors.BLUE}
                style={{ maxWidth: 200, margin: "auto", marginTop: 30 }}
              >
                REGISTRATI
              </CustomButton>
            </ContactForm.Wrapper>
          </ContactForm>
        </RightContainer>
      </Row>
    </Container>
  )
}
