import React, { useState } from "react"
import { Link as GatsbyLink } from "gatsby"

import styled from "styled-components"
import Burger from "../Burger/Burger"
import ChirurgoMenuLeft from "../ChirurgoMenuLeft/ChirurgoMenuLeft"

export const LogoLink = styled(GatsbyLink)`
  cursor: pointer;
  &:hover > img {
    animation: pulse 0.5s;
  }
  @media (max-width: 1480px) {
    flex: 0.2;
  }

  @media (max-width: 1225px) {
    flex: 0.2;
    margin-right: auto;
    margin-bottom: -10px;
    margin-left: 20px;
  }
`

export const Wrapper = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`
export const Container = styled.div`
  position: fixed;
  background-color: #fafafa;
  padding: 10px;
  box-shadow: 0px 3px 6px #00000012;
  height: 80px;
  width: 100%;
  align-content: center;
  z-index: 9;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 15px;
`

const EndDiv = styled.div`
  display: flex;
  align-items: center;
`

const Link = styled(GatsbyLink)`
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  color: ${({ theme }) => theme.lightblue};
`

const MobileBarChirurghi = ({ pathname }) => {
  const [open, setOpen] = useState(false)
  return (
    <Wrapper>
      <Container>
        <Burger open={open} setOpen={setOpen} />

        <ChirurgoMenuLeft open={open} setOpen={setOpen} />
        <LogoLink to={"/"}>
          <img height="50px" src={require("../../images/logo.png")} />
        </LogoLink>
        {pathname != "/chirurgo_register" && (
          <EndDiv>
            <Link to={"/chirurgo_register"}>Registrati gratis!</Link>
          </EndDiv>
        )}
      </Container>
    </Wrapper>
  )
}

export default MobileBarChirurghi
